<template>
  <v-dialog
    v-model="dialogCancelarTransferencia"
    @click:outside="$emit('update:dialogCancelarTransferencia', false)"
    @keydown.esc="$emit('update:dialogCancelarTransferencia', false)"
    width="500"
  >
    <v-card v-if="!loading">
      <v-card-title>
        <v-icon left>mdi-alert</v-icon>
        {{ $tc(`global.${mensagem}`) }}
      </v-card-title>

      <v-card-actions>
        <v-btn
          color="error"
          text
          @click="$emit('update:dialogCancelarTransferencia', false)"
        >
          {{ $tc("global.cancelar") }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="loading"
          small
          color="red"
          class="white--text"
          @click="$emit('update:dialogCancelarTransferencia', false)"
        >
          {{ $tc("global.nao") }}
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="cancelarTransferencia()"
          v-if="!status"
          small
          color="green"
          class="white--text"
        >
          {{ $tc("global.sim") }}
        </v-btn>
      </v-card-actions>
    </v-card>
    <Carregando v-else />
  </v-dialog>
</template>

<script>
import {
  cancelarTransferenciaEstoque
} from "@/api/transferenciaEstoque/index.js";
export default {
  name: "DialogCancelarTransferencia",

  props: {
    dialogCancelarTransferencia: {
      type: Boolean,
      default: false,
    },
    transferencia_cancelar_id: {
      type: Number,
    },
  },

  data() {
    return {
      loading: false,
      mensagem: "cancelarTransferencia",
      status: false,
    };
  },
  methods: {
    cancelarTransferencia(){
      this.loading = true;
      cancelarTransferenciaEstoque(this.transferencia_cancelar_id)
        .then((response) => {
          if (response.status === 200) {
            this.$toast.error(this.$tc("global.canceladosucesso"));
            this.$emit("fetch-transferencias");
            this.$emit("update:dialogCancelarTransferencia", false);
            this.$emit("close");
            this.loading = false;
          }
        })
        .catch(() => {
          this.$toast("global.errordel");
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
